var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-4 mx-4"},[_c('v-btn',{staticClass:"mb-6",attrs:{"color":"accent darken-4","depressed":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить ТК ")],1),_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.transport_companies,"not_resizeble":"","no-data-text":"Транспортные компании отсутствуют"},scopedSlots:_vm._u([{key:"item.name_transport_company",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.name_transport_company)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.name_transport_company)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.name_transport_company))])],1)]}},{key:"item.inn_transport_company",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.$style.min_width},[(item.inn_transport_company)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.inn_transport_company)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.inn_transport_company))])],1)]}},{key:"item.telephone",fn:function(ref){
var item = ref.item;
return [(item.telephone)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.telephone)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(_vm._s(item.telephone))])]}}],null,true)}),_c('DownloadDocDialog',{attrs:{"template_link":_vm.template_link},on:{"close":function($event){_vm.dialog = false}},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('p',[_vm._v(" Уважаемый Партнёр, для добавления новой транспортной компании Вам необходимо сформировать новое приложение к Договору по установленной форме. ")]),_c('p',[_vm._v(" В новом приложении необходимо указать полный перечень используемых транспортных компаний, включающих новую. ")]),_c('p',[_vm._v(" Затем необходимо отправить документ на подпись в систему электронного обмена документами. ")]),_c('p',[_c('strong',[_vm._v(" Без подписания приложения к договору через ЭДО изменения перечня ТК в силу не вступят! ")])])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }